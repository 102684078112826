<template>
    <div>
		<b-modal ref="modalActDGLot" size="lg" hide-footer @show="init_component">
			<template v-slot:modal-title>
				<template>
					{{ $t("action.creer_dg") }}
				</template>
			</template>

			<div class="col-12">
				<div class="row mb-3">
					<label class="col-sm-4 col-form-label">{{ $t('global.date') }} *</label>
					<div class="col-sm-5">
						<div class="row">
							<e-datepicker class="col" v-model="acte_day" mode="date"></e-datepicker>
							<e-datepicker class="col" v-model="acte_time" mode="time"></e-datepicker>
						</div>
					</div>
				</div>

				<div class="form-group">
					<label for="responses" class="col-form-label">{{ $t("global.result") }} *</label>
					<e-select
						v-model="selected_response"
						id="id"
						label="label"
						:placeholder="labelTitleFormat"
						:deselectLabel="deselectLabel"
						:selectedLabel="selectedLabel"
						:selectLabel="enter_to_select"
						:options="responses_dg"
						:searchable="true"
						:show-labels="false"
						:allow-empty="false"
					>
						<template slot="singleLabel" slot-scope="{ option }">{{ option.label }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</div>

				<b-button class="mt-3 btn-block rounded-pill" variant="primary" @click="checkForm">{{ $t("action.creer_dg") }} <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse  class="mr-1"/></b-button>
			</div>
		</b-modal>

		<b-modal ref="modalArticle" size="lg" hide-footer>
			<template v-slot:modal-title>
				<template>
					{{ $t('trigger.entity_type.solde_contract') }}
				</template>
			</template>

			<SoldeContract @submit="closeModal" :couples="couples"/>
		</b-modal>
    </div>
</template>

<script type="text/javascript">
import Acte from '@/mixins/Actes.js'
import ActeType from '@/mixins/ActeType.js'
import Common from '@/assets/js/common'
import Gynecologie from '@/mixins/Gynecologie.js'
import SeasonMareStallionCleaner from '@/assets/js/cache/cleaners/SeasonMareStallionCleaner'

export default {
	name: "ModalActDGLot",
	mixins: [Acte, ActeType, Gynecologie],
	data () {
		return {
			labelTitleFormat: this.getTrad("acte.choose_result"),
			deselectLabel: this.getTrad("global.press_enter_to_remove"),
			selectedLabel: this.getTrad("global.selected_label"),
			enter_to_select: this.getTrad("global.press_enter_to_select"),
			
			processing: false,
			acte_statut: 1,
			acte_day: new Date(),
			acte_time: new Date(),
			responses_dg: [],
			selected_response: null,
			couples: null,
			acte_type_id: null,
			questions_dg: [],
		}
	},
	methods: {
		async init_component() {
			this.responses_dg = []
			this.selected_response = null
			this.processing = false

			this.questions_dg = await this.getQuestionsDG()
			this.acte_type_id = this.questions_dg[0].question_fk

			const responses_dg = this.questions_dg.filter(q => q.question_code == 'Résultat')[0]
			responses_dg.question_questiondata.data.forEach((response, i) => {
				let label = null
                if(this.getTrad('reponses.' + [response]).includes('reponses.')) {
                    label = response
                }
                else {
                    label = this.getTrad('reponses.' + [response])
                }
                
				this.responses_dg.push({id: i, label: label})
			})
		},
		openModal(params) {
			this.couples = params
			this.$refs.modalActDGLot.show()
		},
		openModalContract(contracts) {
			let params = []
			let error = false
			contracts.forEach(contract => {
				if(!contract.avenant || !contract.config || !contract.seasonmarestallion
				|| (contract.seasonmarestallion && !contract.seasonmarestallion.seasonmarestallion_id) || error) {
					error = true
					return
				}
				
				let couple = {}
				if(contract.avenant.avenant_porteuse) {
					couple = {
						seasonmarestallion_id: contract.seasonmarestallion.seasonmarestallion_id,
						horse: {
							horse_nom: contract.config.horse.horse_nom
						},
						porteuse: {
							horse: {
								horse_id: contract.avenant.avenant_porteuse
							}
						}
					}
				}
				else {
					couple = {
						seasonmarestallion_id: contract.seasonmarestallion.seasonmarestallion_id,
						horse: {
							horse_nom: contract.config.horse.horse_nom
						},
						mare: {
							horse: {
								horse_id: contract.avenant.avenant_horse
							}
						}
					}
				}

				params.push(couple)
			})

			if(error) {
				this.failureToast("toast.dg_lot_error")
			}
			else {
				this.couples = params
				this.$refs.modalActDGLot.show()
			}
		},
		closeModal() {
			this.$refs.modalArticle.hide()
			this.submitModal()
		},
		async checkForm() {
			this.processing = true

			await Common.asyncForEach(this.couples, async (couple) => {
				if(this.acte_day && this.acte_time) {
					couple.acte_date = new Date(this.acte_day.getFullYear() + '/' + parseInt(this.acte_day.getMonth()+1) + '/' + this.acte_day.getDate() + ' ' + this.acte_time.getHours() + ":" + this.acte_time.getMinutes())
					couple.acte_date.setHours(this.acte_time.getHours())
					couple.acte_date.setMinutes(this.acte_time.getMinutes())
				}
				else {
					couple.acte_date = new Date()
				}

				let reponses = []
				reponses.push({
					reponse_data: [this.selected_response.label],
					reponse_question: this.questions_dg.filter(q => q.question_code == 'Résultat')[0].question_id
				})

				reponses.push({
					reponse_data: [couple.horse.horse_nom],
					reponse_data_hidden: {
						type: "season_mare_stallion",
						id: couple.seasonmarestallion_id
					},
					reponse_question: this.questions_dg.filter(q => q.question_code == 'Étalon')[0].question_id
				})

				let acte_status = 1
				if(this.acte_date > new Date()) {
					acte_status = 0
				}

				let acte_id = null
				if(couple.porteuse) {
					acte_id = await this.addActes([couple.porteuse.horse.horse_id], acte_status, couple.acte_date, '', this.acte_type_id, undefined, null, [], reponses, null, null)
				}
				else {
					acte_id = await this.addActes([couple.mare.horse.horse_id], acte_status, couple.acte_date, '', this.acte_type_id, undefined, null, [], reponses, null, null)
				}

				const acte = await this.getActeFromId(acte_id[0])

				// const sms = await this.getSeasonMareStallionById(couple.seasonmarestallion_id)
				// await this.recalculDateTerme(sms, acte)

				SeasonMareStallionCleaner.inst().onMutation(couple.seasonmarestallion_id, ['season_mare_stallion'])
			})
			.then(async () => {
				await this.$sync.force(true)
				this.processing = false
				this.submitModal()
				this.$refs.modalActDGLot.hide()
				this.checkSaillieRight()
			})
		},

		async checkSaillieRight() {
			// si la licence n'a pas la monte ou que le DG est positif, je ferme la modale, sinon j'ouvre la modale d'articles
			if(!this.$store.state.userAccess.hasSaillieAccess || this.selected_response.label.includes('DG+')) {
				return false
			}

			this.$refs.modalArticle.show()

		},

		submitModal() {
			this.$emit('submit')
		}
	},

	components: {
		SoldeContract: () => import('@/components/Triggers/SoldeContract'),
	}
}
</script>